import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/zap-onboard-api/zap-onboard-api/services-unmanaged/zap-onboard-home-page/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { SupportFooter } from '../../../components/SupportFooter';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "visa-check-basics"
    }}>{`Visa Check Basics`}</h1>
    <p>{`Canyou has the ability to obtain the Australian working rights for employees.`}</p>
    <ul>
      <li parentName="ul">{`All checks via Department of Home Affairs' VEVO system`}</li>
      <li parentName="ul">{`Easy check of visa status, class and subclass and work rights of
temporary and permanent visa holders provides        `}</li>
      <li parentName="ul">{`Notifications when visa's are approaching expiry`}</li>
      <li parentName="ul">{`Electronic audit trail to avoid penalties under the Employer Sanctions
legislation`}</li>
    </ul>
    <p>{`The following pages provide more detail`}</p>

    <SupportFooter linkTo="/support/visa/adding-a-visa-details-task-to-a-workflow" linkText="Adding a Visa Details Task to a Workflow" mdxType="SupportFooter" />
    <SupportFooter linkTo="/support/visa/completing-a-visa-check" linkText="Completing a Visa Check" mdxType="SupportFooter" />
    <SupportFooter linkTo="/support/visa/reviewing-visa-check-history" linkText="Reviewing Visa Check History" mdxType="SupportFooter" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      